import React, { useRef, useEffect } from 'react'
import { useLocation, Switch } from 'react-router-dom'
import AppRoute from './utils/AppRoute'
import ScrollReveal from './utils/ScrollReveal'

// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views
import Home from './views/Home'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDOCX461asfyggHzK6rxeRL_VEq3Hk9hRg',
  authDomain: 'batikal-4cbe6.firebaseapp.com',
  databaseURL: 'https://batikal-4cbe6.firebaseio.com',
  projectId: 'batikal-4cbe6',
  storageBucket: 'batikal-4cbe6.appspot.com',
  messagingSenderId: '85342223936',
  appId: '1:85342223936:web:a506375d539f2b9167e9a5',
  measurementId: 'G-3ZT8NV9020',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const App = () => {
  const childRef = useRef()
  let location = useLocation()

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path='/' component={Home} layout={LayoutDefault} />
        </Switch>
      )}
    />
  )
}

export default App
