import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Image from '../elements/Image'

const propTypes = {
  ...SectionTilesProps.types,
}

const defaultProps = {
  ...SectionTilesProps.defaults,
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  )

  const sectionHeader = {
    title: 'Thèmes abordés dans les calculs',
    paragraph:
      "L'application a pour but d’aider à dimensionner les composants les plus courants d’un système de chauffage. Elle embarque un design très simple et ludique permettant une prise en main rapide. Tout est pensé pour faire gagner du temps à l’utilisateur, même sur des dimensionnements réputés complexe tel que l'eau chaude sanitaire collective et tertiaire. Les dix-neuf sections qu’embarque le moteur de calcul Batikal, permettent de répondre à quatre-vingt-dix pourcents des questions que l’on peut se poser à la conception, à l’installation ou lors d’une expertise d'un batiment neuf ou en rénovation pour des particuliers, ou pour du collectif voir des batiments tertiaires. En voici un apperçu :",
  }

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>
            <div className='tiles-item reveal-from-bottom'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt='Features tile icon 01'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>Besoin thermique </h4>
                  <p className='m-0 text-sm'>
                    Vous permet de calculer rapidement les deperditions d'un
                    batiment. Cela va du simple coeficient G prérempli au vrai
                    calcul de déperdition en intégrant tous les éléments de la
                    construction. Vous pouvez par ailleurs calculer les
                    déperditions à partir des consommations d'un bâtiment.
                    Element essentiel pour le dimensionnement d'installation
                    telle que la pompe à chaleur.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt='Features tile icon 02'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>L’eau chaude sanitaire</h4>
                  <p className='m-0 text-sm'>
                    Calculez vos systèmes instantanés, semi-instantanés,
                    semi-accumulés, accumulés ; avec une accumulation sanitaire
                    ou primaire pour des batiments collectif et tertiaire.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='400'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt='Features tile icon 03'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>La Tuyauterie</h4>
                  <p className='m-0 text-sm'>
                    Calculez les diamètres de chauffage et d’eau chaude
                    sanitaire en fonction des vitesses et pertes de charges
                    linéaires souhaitées. Dimensionnez ainsi les diamêtres de
                    raccordement en local technique tel que des chaufferies ou
                    en distribution pour des rénovation d'installation
                    sanitaire.
                  </p>
                </div>
              </div>
            </div>

            <div className='tiles-item reveal-from-bottom'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt='Features tile icon 04'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>Le gaz</h4>
                  <p className='m-0 text-sm'>
                    Concevez vos tuyauteries gaz jusqu’à quatre chaudières ;
                    Calculez le débit réel en fonction de la puissance ou votre
                    puissance en fonction de votre débit. Un technicien pourra
                    vérifier que son topgaz est bon. Un ingénieur quant à lui
                    dimensionnera les conduites de gaz pour des chaufferies ou
                    pour des chaudières individuelles.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt='Features tile icon 05'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>Découplage</h4>
                  <p className='m-0 text-sm'>
                    Dimensionnement votre bouteille de découplage pour vos
                    installations de chauffage ou d'eau glacée.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='400'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt='Features tile icon 06'
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>Ventilation</h4>
                  <p className='m-0 text-sm'>
                    Dimensionnez ou contrôlez la bonne taille des ventilations
                    haute et basse nécessaire en chaufferie fioul gaz et bois.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeaturesTiles.propTypes = propTypes
FeaturesTiles.defaultProps = defaultProps

export default FeaturesTiles
