import React, { useState } from 'react'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'
import ButtonGroup from '../elements/ButtonGroup'
import Button from '../elements/Button'
import Image from '../elements/Image'
import Modal from '../elements/Modal'
import appstore from '../../assets/images/appstore.png'
import playstore from '../../assets/images/playstore.png'
const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false)

  const openModal = (e) => {
    e.preventDefault()
    setVideomodalactive(true)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVideomodalactive(false)
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className='hero-content'>
            <h1
              className='mt-0 mb-16 reveal-from-bottom'
              data-reveal-delay='200'
            >
              Bienvenue sur le site de {''}
              <span className='text-color-primary'>BatiKal</span>
            </h1>
            <div className='container-xs'>
              <p
                className='m-0 mb-32 reveal-from-bottom'
                data-reveal-delay='400'
              >
                Batikal est une plateforme unique permettant, à tout moment, de
                concevoir, de dimensionner ou d’expertiser un système
                hydraulique de chauffage, d’eau chaude sanitaire, de
                climatisation et de ventilation.
              </p>
              <div className='reveal-from-bottom' data-reveal-delay='600'>
                <ButtonGroup>
                  <a
                    href='https://itunes.apple.com/fr/app/batikal/id955903737?ls=1&mt=8'
                    title='Icon-512'
                  >
                    <img
                      src={appstore}
                      width='170'
                      height='46'
                      alt='Icon-512'
                    ></img>
                  </a>
                  <Button
                    tag='a'
                    color='primary'
                    wideMobile
                    href='https://app.batikal-app.com/'
                    style={{
                      color: 'white',
                      marginLeft: 30,
                      marginRight: 30,
                      height: 52,
                      width: 170,
                      borderRadius: 5,
                    }}
                  >
                    Accéder
                  </Button>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.fahbe.batikal&hl=fr'
                    title='Icon-512'
                  >
                    <img
                      src={playstore}
                      width='170'
                      height='40'
                      alt='Icon-512'
                    ></img>
                  </a>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className='hero-figure reveal-from-bottom illustration-element-01'
            data-reveal-value='20px'
            data-reveal-delay='800'
          >
            <a
              data-video='https://player.vimeo.com/video/732732885'
              href='#0'
              aria-controls='video-modal'
              onClick={openModal}
            >
              <Image
                className='has-shadow'
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt='Hero'
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id='video-modal'
            show={videoModalActive}
            handleClose={closeModal}
            video='https://player.vimeo.com/video/732732885'
            videoTag='iframe'
          />
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps

export default Hero
